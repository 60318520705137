<template>
  <div id="tosf-application-form" class="">
    <div class="c-app justify-content-center mt-3">
      <div>
        <div class="mb-4">
          <div class="text-center mb-3">
            <div class=" ">
              <img
                src="@/assets/images/ched-header-logo-cropped.png"
                class="mb-3"
              />
            </div>

            <h4 class=" mb-1">
              CHED CARAGA
            </h4>
            <h3 class="font-weight-bold">
              TUITION AND OTHER SCHOOL FEES INCREASE
            </h3>
            <h3></h3>
          </div>
        </div>
        <div class="card rounded shadow-sm">
          <div class="card-body overflow-auto" style="width: 600px;">
            <section v-if="currentStep == 1">
              <div class="">
                <h5 class="font-weight-bold text-center">
                  Please read the instructions carefully.
                </h5>
                <ol class="mt-3">
                  <li>
                    Make sure that all files must be submitted in
                    <strong class="">PDF format</strong>. Double check all
                    documents before submitting.
                  </li>
                  <li>
                    Please ensure that you submit
                    <strong>only one file for each area</strong>. In case you
                    have multiple documents, please merge them into a single
                    file before uploading.
                  </li>
                  <li>
                    It is crucial to thoroughly review and verify all the
                    information before proceeding with the submission.
                    <strong
                      >Once the form is submitted, it cannot be
                      modified.</strong
                    >
                  </li>
                </ol>

                <div class="form-check text-center">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="checkbox"
                    v-model="checkbox"
                  />
                  <label
                    class="form-check-label"
                    for="checkbox"
                    style="font-size: 15px;"
                    >I have read and understood the instructions.</label
                  >
                </div>
                <div class="text-center mt-3">
                  <button
                    :disabled="!checkbox"
                    class="btn btn-lg btn-primary "
                    @click="getNextId"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </section>

            <section v-if="currentStep == 2">
              <div>
                <h5 class="font-weight-bold">Application Details</h5>
                <p>
                  App No:
                  <strong>CHED13-TOSF-{{ form.id }}</strong>
                </p>

                <div class="form-group">
                  <label for="">Name of HEI</label>
                  <select name="" v-model="hei" class="form-control" id="">
                    <option value="" selected disabled hidden>Choose</option>
                    <option v-for="hei in heis" :value="hei" :key="hei.code">{{
                      hei.name
                    }}</option>
                  </select>
                </div>

                <div class="row">
                  <div class="col-auto">
                    <div class="form-group">
                      <label for="">A.Y. Effectivity</label>
                      <select
                        v-model="form.academic_year"
                        name=""
                        class="form-control"
                        id=""
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option
                          v-for="ay in academicYears"
                          :key="ay"
                          :value="ay"
                          >{{ ay }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section v-for="(req, index) in requirements" :key="req.id">
              <div class="" v-if="currentStep == 3 + index">
                <h5 class="text-center">
                  Requirement {{ index + 1 }} of {{ requirements.length }}
                </h5>
                <h5 class="font-weight-bold mt-3">{{ req.name }}</h5>
                <p
                  style="white-space: pre-wrap; text-indent: -4px; font-size: 15px;"
                  class="mt-3"
                >
                  {{ req.description }}
                </p>
                <div class="form-group mt-5">
                  <label for="">
                    Please upload the required document.
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="application/pdf"
                    class="form-control-file"
                    @change="uploadFileEvent($event, index, req)"
                  />
                  <small class="form-text text-muted">
                    <i class="bi bi-exclamation-triangle-fill mx-1"></i> Make
                    sure to upload the <strong>PDF file</strong> only.</small
                  >
                </div>

                <div v-if="fileUploadLoader" class="d-flex align-items-center">
                  <strong class="text-primary">{{ loaderMsg }}</strong>
                  <div
                    class="spinner-border text-primary spinner-border-sm ml-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>

                <div v-if="req.filename" class="">
                  <a
                    href="#"
                    target="_blank"
                    @click.prevent="openUrl(req.path)"
                  >
                    <i class="bi bi-file-pdf"></i> {{ req.filename }}
                    <i class="bi bi-check-circle-fill text-success mx-1"></i
                  ></a>
                </div>
              </div>
            </section>

            <section>
              <div v-if="currentStep == 3 + requirements.length">
                <h5 class="text-center mb-3">
                  Review your application before submitting
                </h5>
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td width="45%">Name of HEI</td>
                      <th>{{ hei.name }}</th>
                    </tr>
                    <tr>
                      <td>A.Y. Effectivity</td>
                      <th>{{ form.academic_year }}</th>
                    </tr>
                    <tr>
                      <th colspan="2">Documentary Requirements</th>
                    </tr>
                    <tr v-for="(req, index) in requirements" :key="index">
                      <td width="60%">
                        {{ `${index + 1}. ${req.description} ` }}
                      </td>
                      <td>
                        <a
                          href=""
                          target="_blank"
                          @click.prevent="openUrl(req.path)"
                        >
                          <i class="bi bi-file-pdf mr-1"></i>
                          {{ req.filename }}</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section>
              <div v-if="currentStep == 4 + requirements.length">
                <div class="text-center mb-3">
                  <h5 class="mb-3">
                    Your application was successfully submitted.
                  </h5>
                  <i
                    class="bi bi-check-circle-fill text-success mb-3"
                    style="font-size: 5em"
                  ></i>

                  <h6>App No</h6>
                  <h1 class="font-weight-bold">CHED13-TOSF-{{ form.id }}</h1>
                  <p>
                    Please take note of this application number for your future
                    reference.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <!-- end of card body -->
        </div>
        <div
          v-if="currentStep > 1 && currentStep != 4 + requirements.length"
          class="d-flex justify-content-between mb-3"
        >
          <button class="btn btn-secondary" @click="prevStep">
            Previous
          </button>
          <button
            v-if="currentStep != 1 && currentStep < 3 + requirements.length"
            :disabled="isDisabled"
            class="btn btn-primary"
            @click="nextStep"
          >
            Next
          </button>
          <button
            v-else
            class="btn btn-primary"
            @click="submit"
            :disabled="isSubmitBtnDisabled"
          >
            Submit
          </button>
        </div>

        <div class="text-center mt-3">
          <button
            v-if="submitAnotherEntry"
            @click.prevent="resetForm"
            class="btn btn-lg btn-primary"
          >
            Submit another entry
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { awardYears } from '../../js/award-years';

export default {
  name: 'TosfApplicationForm',
  data() {
    return {
      academicYears: awardYears,
      loaderMsg:
        'Uploading file. This may take a while. Do not refresh or close this page...',
      getStarted: false,
      heis: [],
      checkbox: false,
      currentStep: 1,
      requirements: [],
      fileUploadLoader: false,
      file: '',
      form: new Form({
        hei_code: '',
        academic_year: '',
      }),
      prefix: 'https://drive.google.com/file/d/',
      hasSubmittedSuccessfully: false,
      submitAnotherEntry: false,
      isDisabled: true,
      isSubmitBtnDisabled: false,
      hei: '',
      program: '',
    };
  },

  methods: {
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    clearAttachments() {
      this.attachments = [];
      let el = document.getElementById('file-upload');
      el.value = '';
    },

    getNextId() {
      axios
        .get('api/tosf/application/next-id')
        .then((res) => {
          this.form.id = res.data;
          this.getStarted = true;
          this.nextStep();
          this.getRequirements();
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Oops.');
        });
    },

    uploadFileEvent(event, index, req) {
      this.file = event.target.files[0];
      this.uploadFileToGoogleDrive(index, req);
    },

    uploadFileToGoogleDrive(index, req) {
      let fd = new FormData();
      this.fileUploadLoader = true;

      fd.append('file', this.file);
      fd.append('requirement_id', req.id);
      fd.append('id', this.form.id);

      $('#progress-bar-modal').modal({
        show: true,
        keyboard: false,
        backdrop: 'static',
      });

      axios
        .post('api/tosf/documentary-requirements/file-upload', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          $('#progress-bar-modal').modal('hide');
          this.requirements[index].filename = res.data.filename;
          this.requirements[index].path = res.data.path;
          document.getElementById('file-upload').value = '';
          this.file = '';
          this.fileUploadLoader = !this.fileUploadLoader;
          this.isValidated();
        })
        .catch((error) => {
          $('#progress-bar-modal').modal('hide');
          toastr.error(error.response.data.message, 'Error');
        });
    },
    nextStep() {
      if (this.isValidated()) {
        this.currentStep++;
      }
    },
    prevStep() {
      this.currentStep--;
    },

    enableNext() {
      if (this.currentStep == 2 && this.requirements.length != 0) {
        this.isDisabled = false;
      }
    },

    isValidated() {
      if (this.currentStep == 1) {
        return true;
      }

      if (this.currentStep == 2 && this.requirements.length != 0) {
        if (!this.isAppDetailsValidated()) {
          toastr.error('Please fill in all fields.', 'Required fields');
          return false;
        }
        return true;
      }

      if (this.currentStep <= 2 + this.requirements.length) {
        if (this.requirements[this.currentStep - 3].filename == '') {
          toastr.error(
            'Please upload the required document.',
            'Required field'
          );
          return false;
        } else {
          return true;
        }
      }

      if (this.currentStep > 2 + this.requirements.length) {
        return true;
      }
    },

    isAppDetailsValidated() {
      if (this.form.academic_year == '' || this.hei.code == '') {
        return false;
      }

      return true;
    },

    getRequirements() {
      axios
        .get('api/tosf/requirements')
        .then((res) => {
          this.requirements = res.data;
          this.requirements = this.requirements.map((obj) => {
            return { ...obj, filename: '', path: '' };
          });
          this.enableNext();
        })
        .catch((err) => {});
    },

    submit() {
      if (
        !confirm(
          'WARNING! \nYou can no longer update this application once submitted. \nAre you sure you want to proceed?'
        )
      ) {
        return;
      }

      this.isSubmitBtnDisabled = true;
      this.loaderMsg = 'Submitting your application...';
      this.fileUploadLoader = true;
      this.form.hei_code = this.hei.code;

      axios
        .post('api/tosf/application', this.form)
        .then((res) => {
          this.nextStep();
          this.submitAnotherEntry = true;
          this.fileUploadLoader = false;
          this.isSubmitBtnDisabled = false;
        })
        .catch((err) => {
          toastr.error('Oops! Unable to submit the application.', 'Error');
          this.isSubmitBtnDisabled = false;
          this.fileUploadLoader = false;
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetForm() {
      this.loaderMsg =
        'Uploading file. This may take a while. Do not refresh or close this page...';
      this.form.reset();
      this.file = '';
      this.currentStep = 1;
      this.hei = '';
      this.submitAnotherEntry = false;
    },
  },
  mounted() {
    this.getHeis();
  },
};
</script>

<style scoped>
ol li {
  padding-bottom: 10px;
  font-size: 15px;
}
</style>
